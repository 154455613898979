import React from 'react';
import SaveEvent from './SaveEvent';
import CardButton from './CardButton';
import './EventCard.css';
import { FaGoogle, FaCalendar, FaShare } from 'react-icons/fa';

const EventCard = ({ event, isExpanded, onExpand, onSaveToggle, user, isSaved, showImages }) => {
    const handleCardClick = () => {
        onExpand(event.id);
    };

    const handleSaveClick = (e) => {
        e.stopPropagation();
    };

    const addToGoogleCalendar = () => {
        const eventDate = new Date(event.Date);
        const endDate = new Date(eventDate.getTime() + 2 * 60 * 60 * 1000); // Assume 2 hours duration
        const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event['Event Name'])}&dates=${eventDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}/${endDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}&details=${encodeURIComponent(event['Event Details'])}&location=${encodeURIComponent(event.Venue)}`;
        window.open(googleCalendarUrl, '_blank');
    };

    const addToICalOutlook = () => {
        const eventDate = new Date(event.Date);
        const endDate = new Date(eventDate.getTime() + 2 * 60 * 60 * 1000); // Assume 2 hours duration
        const iCalContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${eventDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}
DTEND:${endDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}
SUMMARY:${event['Event Name']}
DESCRIPTION:${event['Event Details']}
LOCATION:${event.Venue}
END:VEVENT
END:VCALENDAR`;
        const blob = new Blob([iCalContent], { type: 'text/calendar;charset=utf-8' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${event['Event Name']}.ics`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const shareEvent = (e) => {
        e.stopPropagation();
        const eventUrl = `${window.location.origin}${window.location.pathname}?id=${event.id}`;
        const shareData = {
            title: event['Event Name'],
            text: `Check out this event: ${event['Event Name']}`,
            url: eventUrl
        };

        if (navigator.share && navigator.canShare && navigator.canShare(shareData)) {
            navigator.share(shareData)
                .then(() => console.log('Shared successfully'))
                .catch((error) => {
                    console.log('Error sharing:', error);
                    fallbackShare(eventUrl);
                });
        } else {
            fallbackShare(eventUrl);
        }
    };

    const fallbackShare = (url) => {
        if (navigator.clipboard) {
            const scrollPosition = window.pageYOffset;
            navigator.clipboard.writeText(url)
                .then(() => {
                    alert('Event link copied to clipboard! You can now paste it to share.');
                    setTimeout(() => window.scrollTo(0, scrollPosition), 0);
                })
                .catch(err => {
                    console.error('Could not copy text: ', err);
                    promptManualCopy(url);
                });
        } else {
            promptManualCopy(url);
        }
    };

    const promptManualCopy = (url) => {
        const scrollPosition = window.pageYOffset;
        const textArea = document.createElement("textarea");
        textArea.value = url;
        textArea.style.position = 'fixed';  // Make it invisible
        textArea.style.opacity = 0;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            alert('Event link copied to clipboard! You can now paste it to share.');
        } catch (err) {
            console.error('Failed to copy', err);
            alert('Unable to copy automatically. Please manually copy this link to share:\n\n' + url);
        }
        document.body.removeChild(textArea);
        setTimeout(() => window.scrollTo(0, scrollPosition), 0);
    };

    const eventDate = new Date(event.Date);
    const formattedDate = eventDate.toLocaleDateString('en-US', { 
        month: '2-digit', 
        day: '2-digit', 
        year: '2-digit'
    }).replace(/\//g, '.');
    const dayOfWeek = eventDate.toLocaleDateString('en-US', { weekday: 'short' });

    return (
        <article className={`event-card ${isExpanded ? 'expanded' : ''}`} onClick={handleCardClick}>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Event",
                    "name": event['Event Name'],
                    "startDate": event.Date,
                    "location": {
                        "@type": "Place",
                        "name": event.Venue
                    },
                    "description": event['Event Details']
                })}
            </script>
            <div className="event-header">
                <div className="event-date-container">
                    <span className="event-date">{formattedDate}</span>
                    <span className="event-day">{dayOfWeek}</span>
                </div>
                <div className="event-info">
                    <h3 className="event-name">{event['Event Name']}</h3>
                    <p className="event-venue">{event.Venue}</p>
                </div>
                <div className="save-event-container" onClick={handleSaveClick}>
                    <SaveEvent 
                        eventId={event.id} 
                        onToggle={(newState) => onSaveToggle(event.id, newState)} 
                        user={user} 
                        isSaved={isSaved} 
                    />
                </div>
            </div>
            {!isExpanded && showImages && event.image_link && (
                <div className="event-image-container">
                    <img 
                        src={event.image_link} 
                        alt={event['Event Name']} 
                        className="event-image"
                        onError={(e) => e.target.style.display = 'none'}
                    />
                </div>
            )}
            {isExpanded && (
                <div className="expanded-content" onClick={(e) => e.stopPropagation()}>
                    {event.image_link && (
                        <div className="event-image-container" onClick={handleCardClick}>
                            <img 
                                src={event.image_link} 
                                alt={event['Event Name']} 
                                className="event-image"
                                onError={(e) => e.target.style.display = 'none'}
                            />
                        </div>
                    )}
                    <div className="event-details">
                        <p>{event['Event Details']}</p>
                    </div>
                    <div className="event-buttons">
                        {event.event_link && (
                            <CardButton 
                                href={event.event_link}
                            >
                                Event Link
                            </CardButton>
                        )}
                        {event['Ticket Link'] && (
                            <CardButton 
                                href={event['Ticket Link']}
                            >
                                Buy Tickets
                            </CardButton>
                        )}
                        <div className="bottom-button-row">
                            <button className="icon-button" onClick={addToGoogleCalendar}>
                                <FaGoogle />
                            </button>
                            <button className="icon-button" onClick={addToICalOutlook}>
                                <FaCalendar />
                            </button>
                            <button className="icon-button" onClick={shareEvent}>
                                <FaShare />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </article>
    );
};

export default EventCard;
