import React, { useMemo } from 'react';
import EventCard from './EventCard';
import FeaturedEvent from './featuredEvent';
import './EventListDisplay.css';

// Feature flag - set to false to disable featured events
const ENABLE_FEATURED_EVENTS = false;

const EventListDisplay = ({ events, expandedEventId, onExpand, onSaveToggle, user, savedEventIds, showImages }) => {
    const FEATURED_INTERVAL = 7;
    
    // Create a stable key for the featured pool based on event IDs
    const eventsKey = useMemo(() => 
        events.map(e => e.id).join(','),
        [events]
    );
    
    // Create a pool of potential featured events (events with images)
    const featuredPool = useMemo(() => {
        const eventsWithImages = events.filter(event => event.image_link);
        // Randomly select up to 10 events
        return eventsWithImages
            .sort(() => Math.random() - 0.5)
            .slice(0, 10);
    }, [eventsKey]);

    // Create array with regular events and insert featured events
    const eventsWithFeatured = events.reduce((acc, event, index) => {
        // Only add featured events if enabled
        if (ENABLE_FEATURED_EVENTS && index === 0) {
            const firstFeatured = featuredPool[0];
            if (firstFeatured) {
                acc.push(
                    <FeaturedEvent 
                        key={`featured-${firstFeatured.id}-first`}
                        event={firstFeatured}
                        user={user}
                        onSaveToggle={onSaveToggle}
                        isSaved={savedEventIds.includes(firstFeatured.id)}
                        showImages={showImages}
                    />
                );
            }
        }

        // Add regular event
        acc.push(
            <EventCard 
                key={event.id} 
                event={event} 
                isExpanded={event.id === expandedEventId}
                onExpand={() => onExpand(event.id)}
                onSaveToggle={onSaveToggle}
                user={user}
                isSaved={savedEventIds.includes(event.id)}
                showImages={showImages}
            />
        );

        // Only add interval featured events if enabled
        if (ENABLE_FEATURED_EVENTS && index > 0 && (index + 1) % FEATURED_INTERVAL === 0) {
            const featuredIndex = (Math.floor(index / FEATURED_INTERVAL) % (featuredPool.length - 1)) + 1;
            const featuredEvent = featuredPool[featuredIndex];
            if (featuredEvent) {
                acc.push(
                    <FeaturedEvent 
                        key={`featured-${featuredEvent.id}-${index}`}
                        event={featuredEvent}
                        user={user}
                        onSaveToggle={onSaveToggle}
                        isSaved={savedEventIds.includes(featuredEvent.id)}
                        showImages={showImages}
                    />
                );
            }
        }

        return acc;
    }, []);

    return (
        <div className="event-list">
            {eventsWithFeatured}
        </div>
    );
};

export default EventListDisplay;